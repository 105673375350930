import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { BaseSyntheticEvent, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Button,
  Avatar,
  IconButton,
} from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import MIconButton from 'src/components/@material-extend/MIconButton';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { logout, user, logoutUrl, loginUrl } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = (e: BaseSyntheticEvent) => {
    logout(logoutUrl, loginUrl);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 22,
          height: 22,
          zIndex: 9999,
          top: '12px',
          right: '12px',
          position: 'fixed',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        {/* <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" /> */}
        <MyAvatar />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.email}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.last_name}
          </Typography> */}
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option, index: number) => (
          <MenuItem
            key={100 + index}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}

        {/* <Tooltip title={'My Organization'} placement="bottom-end">
          <MenuItem
            to={'#'}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Typography variant="body2" style={{ color: '#0045FF' }}>
              Org :
            </Typography>
            {userInfo?.name || ''}
          </MenuItem>
        </Tooltip> */}

        {/* {feedback.map((item: any, index: number) => (
          <Tooltip key={200 + index} title={item.title} placement="bottom-end">
            <MenuItem
              to={item.to}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Typography variant="body2" style={{ color: item.color }}>
                {item.type} :
              </Typography>
              {item.date}
            </MenuItem>
          </Tooltip>
        ))} */}

        {/* <Box sx={{ p: 2, pt: 1.5 }}>
          <Feedback env={feedback} />
        </Box> */}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
